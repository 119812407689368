import React from 'react';
import { Col, Hero, LinkButton } from '@visalex/core';

import { NextPage } from 'next';
import { ErrorProps } from 'next/error';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import styles from '@/components/NotFound/NotFound.module.scss';

type NotFoundPageProps = ErrorProps;

const NotFoundPage: NextPage<NotFoundPageProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles['not-found']}>
      <Hero className={styles['not-found__hero']}>
        <Col xs={12} lg={8} className="mx-auto">
          <h1>{t('not-found.title')}</h1>
          <p>{t('not-found.subtitle')}</p>
          <LinkButton href="/" size="lg">
            {t('not-found.go-to-main')}
          </LinkButton>
        </Col>
      </Hero>
    </div>
  );
};

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
  };
}

export default NotFoundPage;
